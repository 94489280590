import React from "react";
import Slider from "react-slick";
import SlickArrowLeft from "./../SlickArrowLeft";
import SlickArrowRight from "./../slickArrowRight";
import * as s from "./pricing.module.css";
import TrialButton from "./../trialButton/trialButton";

const Pricing = ({ bundles }) => {
  const sliderSettings = {
    dots: true,
    arrows: false,
    initialSlide: 1,
    slidesToShow: 3,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 991.9,
        settings: {
          slidesToShow: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 575.9,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  };
  return (
    <>
      <section className={s.priceBlock}>
        <div className="container">
          <Slider {...sliderSettings}>
            {!!bundles &&
              bundles.map((bundle, bundleIndex) => {
                let classes = [s.priceItem, "d-flex", "flex-column"];
                switch (bundle.type) {
                  case "green":
                    classes.push(s.greenBlock);
                    break;
                  case "orange":
                    classes.push(s.orangeBlock);
                    break;
                  default:
                    break;
                }

                return (
                  <div className="col-12 py-3" key={`price-list-${bundleIndex}`}>
                    <div className={classes.join(" ")}>
                      {bundle.popular === true && (
                        <div className={s.mostBtn}>MOST POPULAR</div>
                      )}
                      <div className={s.priceNum}>{bundle.price}</div>
                      <div className={s.priceTitle}>{bundle.title}</div>
                      <ul className={s.priceList + " " + "bundles"}>
                        {!!bundle.solutions &&
                          bundle.solutions.map((l, li) => {
                            return (
                              <li class="solution" key={`price-${bundleIndex}-${li}`}>
                                <span>{l}</span>
                              </li>
                            );
                          })}
                        {!!bundle.highlights &&
                          bundle.highlights.map((l, li) => {
                            return (
                              <li class="highlight" key={`price-${bundleIndex}-${li}`}>
                                <span>{l}</span>
                              </li>
                            );
                          })}
                        {!!bundle.addons &&
                          bundle.addons.map((l, li) => {
                            return (
                              <li class="addon" key={`price-${bundleIndex}-${li}`}>
                                <span>{"* " + l + " " + "(add-on)"}</span>
                              </li>
                            );
                          })}

                      </ul>
                      <TrialButton scrollToForm={bundle.url} classes={[s.priceBtn]}>
                        GET STARTED FOR FREE
                      </TrialButton>
                    </div>
                  </div>
                );
              })}
          </Slider>
        </div>
      </section>
    </>
  );
};

export default Pricing;
