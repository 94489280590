import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Banner from "../components/banner/banner";
import ContactForm from "../components/contactForm/form";
import Faq from "../components/faq/faq";
import Features from "../components/features/features";
import Footer from "../components/footer/footer";
import Header from "../components/header/header";
import News from "../components/news/news";
import Pricing from "../components/pricing/pricing";
import Seo from "../components/seo";
import SingleDevice from "../components/singleDevice/singleDevice";
import Testimonials from "../components/testimonials/testimonials";
import Title from "../components/title/title";
import TrialButton from "../components/trialButton/trialButton";
import bannerBG from "../images/Pricing/banner.png";
import iconAdv1 from "../images/Pricing/icon-adv-1.png";
import iconAdv2 from "../images/Pricing/icon-adv-2.png";
import iconAdv3 from "../images/Pricing/icon-adv-3.png";
import * as s from "../styles/about.module.css";

//  markup
const PricingPage = ({ location, data }) => {
  const formRef = React.useRef(null);
  const scrollToForm = () => {
    formRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const singleDeviceProps = {
    title: `Pricing`,
    h1: true,
    subtitle: (
      <>
        We have 3 tailor made packages for you to choose from
        <br /> based on your company’s needs.
      </>
    ),
    image: (
      <>
        <StaticImage
          placeholder="none"
          loading="eager"
          alt="menu management"
          // src="../images/Pricing/tablet-old.png"
          src="../images/Pricing/tablet.png"
          className="mw-100"
        />
      </>
    ),
  };

  const bundles = [
    {
      title: "Essential",
      price: (
        <>
          $99 <span>/month</span>
        </>
      ),
      type: "green",
      popular: false,
      solutions: [
        "Ordering Website 2.0",
        "Menu Management",
        "3rd Party Integration",
        "Delivery Fulfillment",
        "Business / Experience Review",
        "Product / Food Review",
        "Google Business Optimization",
        "Batch & Analytic Reporting",
        "24/7 Support",
      ],
      highlights: [
      ],
      addons: [
        "Local Business Listing (SEO)",
        "SMS Marketing",
        "Email Marketing",
        "Yelp Promotions",
        "Dine-In Ordering",
        "Multi-Brand Management",
        "POS Integration",
      ],

      url: "#form",
    },
    {
      title: "Professional",
      price: (
        <>
          $249 <span>/month</span>
        </>
      ),
      type: "orange",
      popular: true,
      solutions: [
        "Ordering Website 2.0",
        "Menu Management",
        "3rd Party Integration",
        "Delivery Fulfillment",
        "Business / Experience Review",
        "Product / Food Review",
        "Google Business Optimization",
        "Local Business Listing (SEO)",
        "SMS Marketing",
        "Email Marketing",
        "Marketing Materials",
        "POS Integration",
        "Batch & Analytic Reporting",
        "24/7 Support",
      ],
      highlights: [
        "Business Analytics Review (2H)",
      ],
      addons: [
        "Yelp Promotions",
        "Dine-In Ordering",
        "Multi-Brand Management",
      ],

      url: "#form",
    },
    {
      title: "Growth",
      price: (
        <>
          $399 <span>/month</span>
        </>
      ),
      type: "green",
      popular: false,
      solutions: [
        "Ordering Website 2.0",
        "Menu Management",
        "3rd Party Integration",
        "Delivery Fulfillment",
        "Business / Experience Review",
        "Product / Food Review",
        "Google Business Optimization",
        "Local Business Listing (SEO)",
        "SMS Marketing",
        "Email Marketing",
        "Marketing Materials",
        "POS Integration",
        "Batch & Analytic Reporting",
        "24/7 Support",
      ],
      highlights: [
        "Business Review Publishing",
        "Business Analytics Review (Quarterly)",
      ],
      addons: [
        "Yelp Promotions",
        "Dine-In Ordering",
        "Multi-Brand Management",
      ],

    },
  ];

  const bannerProps = {
    layout: 2,
    bg: bannerBG,
    title: "MULTI LOCATIONS",
    subtitle: "More than 5 Locations.",
    scrollToForm: scrollToForm,
    buttonText: "Contact Us",
    bottomText: "Connect with us for pricing",
  };

  const features = [
    {
      bg: "#F1F7FF",
      icon: iconAdv1,
      title: (
        <>
          Easily keep track of
          <br /> your online orders
        </>
      ),
      description:
        "Third party orders are now automated.  Life is so much easier.",
    },
    {
      bg: "#DEFFEE",
      icon: iconAdv2,
      title: (
        <>
          Synchronize menus in
          <br /> a matter of minutes
        </>
      ),
      description: <>Make changes to your menus in couple of clicks.</>,
    },
    {
      bg: "#FFF7E3",
      icon: iconAdv3,
      title: (
        <>
          View detailed reports
          <br /> easily
        </>
      ),
      description: "Easily track your reports to get thorough insights",
    },
  ];

  const testimonials = [
    {
      image: (
        <StaticImage
          placeholder="none"
          loading="eager"
          src="../images/Pricing/restaurant-owner.png"
          alt="restaurant owner"
          className="mw-100 w-100"
        />
      ),
      text: `I love their 3rd Party Delivery Integration and Commission-Free Website with Flex Delivery.
                I've increased my sales by 33% in the first month alone!`,
      author: "Armond Zarookian",
      to: "/customer-stories/",
      position: "Slash Pizza Owner",
    },
  ];

  const testimonialsProps = {
    layout: 3,
    items: testimonials,
    title: <b>Hear From Our Customers</b>,
    tag: "",
  };

  const questions = [
    {
      question: `How long does it take to set up Orders.co?`,
      answer: `Orders.co’s integration and setup takes around 1-2 weeks, depending on the current workload.
                For a more detailed estimate, contact a sales representative.`,
    },
    {
      question: `Which delivery platforms are supported by Orders.co?`,
      answer: `Currently Orders.co supports Grubhub, Postmates, UberEats, and DoorDash. With many more
                on the way. Restaurant partners are also encouraged to request new platform integrations.`,
    },
    {
      question: `How much does Orders.co setup cost?`,
      answer: `Orders.co setup fee is $100.`,
    },
    {
      question: `How will orders from multiple virtual stores be displayed in Orders.co?`,
      answer: `All your integrated virtual stores are shown together in the Orders.co “Orders” list.
                Next to each order is the Brand Name and Platform, so staff can easily identify every order.`,
    },
    {
      question: `How can we integrate an Orders.co ordering site into our existing website?`,
      answer: `An Orders.co ordering site can be easily integrated into your existing website through
                the addition of an “Order Now” button. Customers can click this button and quickly be
                redirected to place their order.`,
    },
  ];

  return (
    <>
      <Seo
        title={"Pricing"}
        description={""}
        pageSeo={data.wpPage}
        location={location}
      />
      <Header scrollToForm={scrollToForm}></Header>
      <div className={s.bodyShadow}>
        <StaticImage
          placeholder="none"
          loading="eager"
          alt="shadow"
          src="../images/AboutUs/shadow.svg"
        />
      </div>
      <main>
        <SingleDevice {...singleDeviceProps} />
        <Title underlined={true} layout="bolder">
          Pricing
        </Title>
        <Pricing bundles={bundles} />

        <Banner {...bannerProps} />

        <Title layout="bolder" underlined={true}>
          Our Features
        </Title>
        <Features items={features} />
        <div className="container d-flex justify-content-center">
          <TrialButton
            classes={["trial-button bg-orange cursor-pointer"]}
            scrollToForm={scrollToForm}
          >
            Request a Free Trial
          </TrialButton>
        </div>
        <Testimonials {...testimonialsProps} />
        <News />
        <Faq items={questions} />
        <div ref={formRef}>
          <ContactForm></ContactForm>
        </div>
      </main>
      <Footer scrollToForm={scrollToForm}></Footer>
    </>
  );
};

export const query = graphql`
  query {
    wpPage(slug: { eq: "pricing" }) {
      title
      id
      seo {
        metaKeywords
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        focuskw
        opengraphImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        twitterImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        opengraphType
      }
    }
  }
`;

export default PricingPage;
