// extracted by mini-css-extract-plugin
export var greenBlock = "pricing-module--green-block--228df";
export var miniTitle = "pricing-module--mini-title--af413";
export var mostBtn = "pricing-module--most-btn--6c206";
export var orangeBlock = "pricing-module--orange-block--e3128";
export var priceBlock = "pricing-module--price-block--8ab25";
export var priceBtn = "pricing-module--price-btn--4da20";
export var priceItem = "pricing-module--price-item--06e82";
export var priceLis = "pricing-module--price-lis--beabe";
export var priceList = "pricing-module--price-list--5bfe4";
export var priceNum = "pricing-module--price-num--9d264";
export var priceTitle = "pricing-module--price-title--4a29d";
export var priceing = "pricing-module--priceing--354dc";
export var title = "pricing-module--title--2843e";