import React from "react";
import * as s from "./banner.module.css";
import TrialButton from "./../trialButton/trialButton";

const Banner = ({ layout, bg, title, subtitle, scrollToForm, buttonText, bottomText, content }) => {
  if (!buttonText) {
    buttonText = "GET STARTED FOR FREE"
  }
  return (
    <>
      <section className={s.fullBgBlock}>
        <div className="container">
          {1 == layout &&
            <div
              className={`${s.row} row d-flex align-items-center justify-content-center flex-column`}
              style={{ backgroundImage: `url(${bg})` }}
            >
              <h2>{title}</h2>
              {!!content && content}
              {!content &&
                <TrialButton
                  scrollToForm={scrollToForm}
                  classes={["trial-button bg-orange cursor-pointer mt-4"]}
                >
                  {buttonText}
                </TrialButton>
              }
            </div>
          }
          {2 == layout &&
            <div
              className={`${s.row} ${s.row2} row d-flex align-items-center justify-content-center flex-column`}
              style={{ backgroundImage: `url(${bg})` }}
            >
              <h2>{title}</h2>
              {!!subtitle &&
                <h3 className={s.subtitle}>{subtitle}</h3>
              }
              <TrialButton
                layout={2}
                scrollToForm={scrollToForm}
                classes={["trial-button bg-green cursor-pointer"]}
              >
                {buttonText}
              </TrialButton>
              {!!bottomText &&
                <div className={s.bottomText}>{bottomText}</div>
              }
            </div>
          }
          {3 == layout &&
            <div
              className={`${s.row} ${s.row3} row d-flex align-items-center justify-content-between flex-column flex-md-row flex-nowrap`}
              style={{ backgroundImage: `url(${bg})` }}
            >
              <h2>
                {title}
                {!!subtitle &&
                  <big>{subtitle}</big>
                }
              </h2>

              <TrialButton
                layout={3}
                scrollToForm={scrollToForm}
                classes={["trial-button bg-black cursor-pointer"]}
              >
                {buttonText}
              </TrialButton>
            </div>
          }
        </div>
      </section>
    </>
  );
};

export default Banner;
